import * as React from "react";
import Helmet from "react-helmet";

import { Link } from "gatsby";

import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { respondTo } from "../_respondTo";

import { StaticImage } from "gatsby-plugin-image";
import logo from "../images/twg_logo.svg";

import logoLsnifa from "../images/lsnifa-logo-white.svg";
import logoNfp from "../images/nfp-logo-white.svg";
import logoTwm from "../images/twm-logo-white.svg";
import logoWatsonLaird from "../images/watsonlaird-logo-white.svg";
import logoLeeStrathy from "../images/leestrathy-logo-white.svg";
import logoTag from "../images/tagfp-logo-white.svg";
import logoCutter from "../images/cutterfp-logo-white.svg";
import logoVeracity from "../images/veracity-logo-white.svg";
import logoDestination from "../images/destinationfp-logo-white.svg";
import logoFlowersMcEwan from "../images/flowersmcewan-logo-white.svg";

import { PageWrapper, ScreenWrapper } from "../components/layout";
import Navigation from "../components/navigation";
import ContactForm from "../components/contact-form";

import { Zoom, Slide, Fade } from "react-reveal";
import Footer from "../components/footer";

const GlobalStyle = createGlobalStyle`
  body{
    margin:0;
    font-family: sans-serif;
  }

  .firmModal{
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;

    width: 90vw;
    max-width:960px;
    margin:40px auto;

  }

  .firmModalOverlay{
    position: fixed;
    inset: 0px;
    background-color: rgba(15, 15, 15, 0.55);
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

`;

const LogoWrapper = styled.div`
  margin: auto;
  width: 50%;
  max-width: 250px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  margin: auto;
  padding: 0 5vw;
  text-align: center;
  color: #020f73;
`;

const FirmsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(2, 15, 115, 0.7);
  margin: 0 auto;
  max-width: 960px;

  ${respondTo.xsmall`
    flex-direction: row;
    background-color: rgba(2,15,115,0.7);
  
  `}
`;

const FirmLink = styled(Link)`
  display: inline-block;

  margin: 18px 20px;
  text-align: center;
  border-bottom: 2px solid transparent;

  transition: border 500ms ease-in;

  &:hover {
    border-bottom-color: white;
  }
`;

const BigText = styled.div`
  text-align: center;
  font-size: 4vh;
  padding: 0.5em 10vw;
  margin-top: 1em;

  ${respondTo.xsmall`
  font-size: 5vh;
  `}
`;

const SectionWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow-x: hidden;

  h2 {
    text-decoration: underline;
  }
`;

const MoreButton = styled(Link)`
  color: white;
  display: inline;

  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.5vh;
  text-decoration: none;
  padding: 0.5em;
  border-radius: 0.25em;

  background-color: #041dd7;

  &:hover {
    background-color: #132ffb;
  }
`;

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>True Wealth Group</title>
        <meta name="description" content="Website of True Wealth Group" />
        <meta name="keywords" content="True Wealth Group" />
        <script defer src="https://unpkg.com/@tinybirdco/flock.js"
        data-host="https://api.tinybird.co"
        data-token="p.eyJ1IjogImI3YzA5NWQxLThiMTctNGQ5Ni04ODUwLWUxNjM0YmU5NzgyOCIsICJpZCI6ICI4Njg5NGY0MC00ZjBlLTRiMGMtYTI2YS0xMmNkZTBlYjEyOWMifQ.cXvEL-7r0R4anvPPduWPoDsUKPPZeyI54Z2BuA_L5E8"
       >
        </script>
      </Helmet>

      <PageWrapper>
        <GlobalStyle />
        <StaticImage
          alt="Background image of person walking up path on a mountain"
          src={"../images/bg-img.jpg"}
          formats={["auto", "webp", "avif"]}
          style={{
            position: "fixed",
            top: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "-2",
          }}
          loading="eager"
        />

        <ScreenWrapper
          style={{
            backgroundColor: "rgba(215,215,215,0.7)",
            position: "relative",
          }}
        >
          <Navigation />
          <LogoWrapper>
            <img
              src={logo}
              style={{ width: "100%", marginTop: "15%", aspectRatio: "1/1" }}
              alt="True Wealth Group logo"
            />
          </LogoWrapper>{" "}
          <ContentWrapper>
            <h1>
              Helping people find their 'True Wealth' through the power of{" "}
              <br /> True financial planning wealth management
            </h1>
          </ContentWrapper>
          <FirmsWrapper>
            <Zoom>
              <FirmLink
                to="/firms/law-society-ni-financial-advice/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <img
                  src={logoLsnifa}
                  style={{ width: "225px", height: "117.34px" }}
                  alt={"Law Society (NI) Financial Advice logo"}
                />
              </FirmLink>
              <FirmLink
                to="/firms/navigator-financial-planning/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <img
                  src={logoNfp}
                  style={{ width: "150px", height: "106.59px" }}
                  alt={"Navigator Financial Planning logo"}
                />
              </FirmLink>
              <FirmLink
                to="/firms/true-wealth-management/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <img
                  src={logoTwm}
                  style={{ width: "120px", height: "62.86px" }}
                  alt={"True Wealth Management logo"}
                />
              </FirmLink>
              <FirmLink
                to="/firms/watson-laird/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <img
                  src={logoWatsonLaird}
                  style={{ width: "200px", height: "89.06px" }}
                  alt={"Watson Laird logo"}
                />
              </FirmLink>
              <FirmLink
                to="/firms/lee-strathy/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <img
                  src={logoLeeStrathy}
                  style={{ width: "150px", height: "51.14px" }}
                  alt={"Lee Strathy logo"}
                />
              </FirmLink>
              <FirmLink
                to="/firms/tag-financial-planning/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <Zoom>
                  <img
                    src={logoTag}
                    style={{ width: "180px", height: "49.42px" }}
                    alt={"TAG Financial Planning logo"}
                  />
                </Zoom>
              </FirmLink>
              <FirmLink
                to="/firms/cutter-co-financial-planning/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <img
                  src={logoCutter}
                  style={{ width: "150px", height: "37.23px" }}
                  alt={"Cutter & Co Financial Planning logo"}
                />
              </FirmLink>

              <FirmLink
                to="/firms/flowers-mcewan/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <img
                  src={logoFlowersMcEwan}
                  style={{ width: "150px", height: "45.59px" }}
                  alt={"Flowers McEwan logo"}
                />
              </FirmLink>

              <FirmLink
                to="/firms/destination-financial-planning/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <img
                  src={logoDestination}
                  style={{ width: "100px", height: "124.45px" }}
                  alt={"Destination Financial Planning logo"}
                />
              </FirmLink>
              <FirmLink
                to="/firms/veracity-ats/"
                state={{
                  modal: true,
                  closeToUrl: "/",
                }}
              >
                <img
                  src={logoVeracity}
                  style={{ width: "200px", height: "32.98px" }}
                  alt={"Veracity Asset Transformation Service logo"}
                />
              </FirmLink>
            </Zoom>
          </FirmsWrapper>{" "}
          <div style={{ height: "10vh" }} />
          <svg
            height="5vh"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={{
              width: "100%",
              marginTop: "1em",
              display: "block",
              bottom: "0",
              position: "absolute",
            }}
          >
            <path
              fill="rgba(2,15,115,1)"
              d="M 0 0 L 45 0 L 50 100 L 55 0 L 100 0 L 100 100 L 0 100 Z"
            />
          </svg>
        </ScreenWrapper>
        <div
          style={{
            width: "100%",

            zIndex: "-1",
            backgroundColor: "rgba(69,69,69,0.8)",
          }}
        >
          <ScreenWrapper
            style={{
              backgroundColor: "rgba(2,15,115,1)",
              color: "white",
            }}
            className="cliparrow"
          >
            <SectionWrapper>
              <h2>About Us</h2>
              <Slide right>
                <BigText>
                  The True Wealth Group’s management team has been providing
                  financial planning, wealth management, consultancy and other
                  services together for 20 years
                </BigText>
              </Slide>

              <MoreButton to="/about-us" aria-label="read more about us">
                Read more
              </MoreButton>
            </SectionWrapper>
          </ScreenWrapper>
          <ScreenWrapper
            style={{
              color: "white",
              position: "relative",
            }}
          >
            <SectionWrapper>
              <h2>Finding True Wealth</h2>
              <Slide left>
                <BigText>
                  Being 'wealthy' means different things to different people. We
                  help clients discover and achieve their true wealth
                </BigText>
              </Slide>
              <MoreButton
                to="/finding-true-wealth"
                aria-label="read more about finding true wealth"
              >
                Read more
              </MoreButton>
            </SectionWrapper>
            <svg
              height="5vh"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={{
                width: "100%",
                marginTop: "1em",
                display: "block",
              }}
            >
              <path
                fill="rgba(2,15,115,1)"
                d="M 0 0 L 45 0 L 50 100 L 55 0 L 100 0 L 100 100 L 0 100 Z"
              />
            </svg>
          </ScreenWrapper>
          <ScreenWrapper
            style={{
              backgroundColor: "rgba(2,15,115,1)",
              color: "white",
            }}
            className="cliparrow"
          >
            <SectionWrapper>
              <h2>Our Purpose</h2>
              <Slide right>
                <BigText>
                  We are building one of the UK’s leading financial planning
                  wealth management groups
                </BigText>
              </Slide>
              <Fade delay={250}>
                <BigText style={{ marginTop: "1em" }}>
                  {" "}
                  – but doing so with a difference
                </BigText>
              </Fade>
              <MoreButton
                to="/our-purpose"
                aria-label="read more about our purpose"
              >
                Read more
              </MoreButton>
            </SectionWrapper>
          </ScreenWrapper>
          <ScreenWrapper
            style={{
              color: "white",
              position: "relative",
            }}
          >
            <SectionWrapper>
              <h2>Growth Strategy</h2>
              <Slide left>
                <BigText>
                  We believe interweaving True financial planning and True
                  wealth management can be a powerful combination for clients
                </BigText>
              </Slide>
              <MoreButton
                to="/growth-strategy"
                aria-label="read more about our growth strategy"
              >
                Read more
              </MoreButton>
            </SectionWrapper>
            <svg
              height="5vh"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={{
                width: "100%",
                marginTop: "1em",
                display: "block",
              }}
            >
              <path
                fill="rgba(2,15,115,1)"
                d="M 0 0 L 45 0 L 50 100 L 55 0 L 100 0 L 100 100 L 0 100 Z"
              />
            </svg>
          </ScreenWrapper>
          <ScreenWrapper
            style={{
              backgroundColor: "rgba(2,15,115,1)",
              color: "white",
            }}
            className="cliparrow"
          >
            <SectionWrapper>
              <h2>Careers</h2>
              <Slide right>
                <BigText>We're hiring!</BigText>
              </Slide>
              <Fade delay={250}>
                <BigText>
                  We are building an exceptional business and that requires
                  exceptional people
                </BigText>
              </Fade>
              <MoreButton to="/careers" aria-label="read more about careers">
                Read more
              </MoreButton>
            </SectionWrapper>
          </ScreenWrapper>
          <ScreenWrapper
            style={{
              color: "white",
              position: "relative",
            }}
            id="ourfirms"
          >
            <SectionWrapper>
              <h2>Our Firms</h2>
              <Slide left>
                <BigText>
                  Click to read a bit more about each of our firms
                </BigText>
              </Slide>{" "}
              <FirmsWrapper>
                <Zoom>
                  <FirmLink
                    to="/firms/law-society-ni-financial-advice/"
                    state={{
                      modal: true,
                      closeToUrl: "/#ourfirms",
                    }}
                  >
                    <img
                      src={logoLsnifa}
                      style={{ width: "225px", height: "117.34px" }}
                      alt={"Law Society (NI) Financial Advice logo"}
                    />
                  </FirmLink>
                  <FirmLink
                    to="/firms/navigator-financial-planning/"
                    state={{
                      modal: true,
                      closeToUrl: "/#ourfirms",
                    }}
                  >
                    <img
                      src={logoNfp}
                      style={{ width: "150px", height: "106.59px" }}
                      alt={"Navigator Financial Planning logo"}
                    />
                  </FirmLink>
                  <FirmLink
                    to="/firms/true-wealth-management/"
                    state={{
                      modal: true,
                      closeToUrl: "/#ourfirms",
                    }}
                  >
                    <img
                      src={logoTwm}
                      style={{ width: "120px", height: "62.86px" }}
                      alt={"True Wealth Management logo"}
                    />
                  </FirmLink>
                  <FirmLink
                    to="/firms/watson-laird/"
                    state={{
                      modal: true,
                      closeToUrl: "/#ourfirms",
                    }}
                  >
                    <img
                      src={logoWatsonLaird}
                      style={{ width: "200px", height: "89.06px" }}
                      alt={"Watson Laird logo"}
                    />
                  </FirmLink>
                  <FirmLink
                    to="/firms/lee-strathy/"
                    state={{
                      modal: true,
                      closeToUrl: "/#ourfirms",
                    }}
                  >
                    <img
                      src={logoLeeStrathy}
                      style={{ width: "150px", height: "51.14px" }}
                      alt={"Lee Strathy logo"}
                    />
                  </FirmLink>
                  <FirmLink
                    to="/firms/tag-financial-planning/"
                    state={{
                      modal: true,
                      closeToUrl: "/#ourfirms",
                    }}
                  >
                    <Zoom>
                      <img
                        src={logoTag}
                        style={{ width: "180px", height: "49.42px" }}
                        alt={"TAG Financial Planning logo"}
                      />
                    </Zoom>
                  </FirmLink>
                  <FirmLink
                    to="/firms/cutter-co-financial-planning/"
                    state={{
                      modal: true,
                      closeToUrl: "/#ourfirms",
                    }}
                  >
                    <img
                      src={logoCutter}
                      style={{ width: "150px", height: "37.23px" }}
                      alt={"Cutter & Co Financial Planning logo"}
                    />
                  </FirmLink>
                  <FirmLink
                to="/firms/flowers-mcewan/"
                state={{
                  modal: true,
                  closeToUrl: "/#ourfirms",
                }}
              >
                <img
                  src={logoFlowersMcEwan}
                  style={{ width: "150px", height: "45.59px" }}
                  alt={"Flowers McEwan logo"}
                />
              </FirmLink>
                  <FirmLink
                    to="/firms/destination-financial-planning/"
                    state={{
                      modal: true,
                      closeToUrl: "/#ourfirms",
                    }}
                  >
                    <img
                      src={logoDestination}
                      style={{ width: "100px", height: "124.45px" }}
                      alt={"Destination Financial Planning logo"}
                    />
                  </FirmLink>
                  <FirmLink
                    to="/firms/veracity-ats/"
                    state={{
                      modal: true,
                      closeToUrl: "/#ourfirms",
                    }}
                  >
                    <img
                      src={logoVeracity}
                      style={{ width: "200px", height: "32.98px" }}
                      alt={"Veracity Asset Transformation Service logo"}
                    />
                  </FirmLink>
                </Zoom>
              </FirmsWrapper>
              <div style={{ height: "6vh" }}></div>
            </SectionWrapper>
            <svg
              height="5vh"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={{
                width: "100%",
                marginTop: "1em",
                display: "block",
              }}
            >
              <path
                fill="rgba(2,15,115,1)"
                d="M 0 0 L 45 0 L 50 100 L 55 0 L 100 0 L 100 100 L 0 100 Z"
              />
            </svg>
          </ScreenWrapper>
          <ScreenWrapper
            style={{
              backgroundColor: "rgba(2,15,115,1)",
              color: "white",
            }}
          >
            <SectionWrapper>
              <h2>Contact Us</h2>
              <BigText>
                <Slide left>Send us a message</Slide>
              </BigText>
              <div
                style={{ width: "100%", maxWidth: "960px", margin: "0 auto" }}
              >
                <ContactForm />
              </div>
            </SectionWrapper>
          </ScreenWrapper>
        </div>
      </PageWrapper>
      <Footer></Footer>
    </>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <title>True Wealth Group</title>
    <meta name="description" content="Website of True Wealth Group" />
    <meta name="keywords" content="True Wealth Group" />
  </>
);
